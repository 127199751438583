<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">落宗明细表</p>
        </div>
        <el-tabs v-model="activeName">
            <el-tab-pane label="列表" name="a">
                <div class="">
                    <!-- <el-input v-model="name" placeholder="任务名称" class="iw" ></el-input> -->
                    <el-input v-model="zdmc" placeholder="宗地名称" class="iw" ></el-input>
                    <el-input v-model="bdcdyh" placeholder="不动产单元号" class="iw" ></el-input>
                    <el-select v-model="fejdw" placeholder="二级单位"  filterable  clearable class="iw">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                    <el-select v-model="zdlx" placeholder="土地用途"  filterable  clearable class="iw">
                            <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                    <el-cascader v-model="regions" ref="cascaderAddr" :options="options" :props="cityPropsB"  placeholder="省市区"  class="iw" filterable clearable></el-cascader>
                    <el-select v-model="zslx" placeholder="证书类型"  filterable  clearable class="iw">
                        <el-option v-for="item in zslxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                    <!-- <el-select v-model="sfyz" placeholder="是否有证（批复）"  filterable  clearable class="iw">
                            <el-option label="是" value="1" ></el-option>
                            <el-option label="否" value="0" ></el-option>
                    </el-select> -->
                    <!-- <el-select v-model="mjsfyy" placeholder="面积是否一样"  filterable  clearable class="iw">
                            <el-option label="是" value="0" ></el-option>
                            <el-option label="否" value="1" ></el-option>
                    </el-select> -->
                    <el-select v-model="zydwid" placeholder="作业单位"  filterable  clearable class="iw" @change="settjrList">
                            <el-option v-for="item in zydwList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select>
                    <!-- <el-select v-model="tjr" placeholder="选择提交人"  filterable  clearable class="iw" v-show="zydwid!=''">
                            <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                    </el-select> -->
                    <el-button type="primary" @click="searchPost">查询</el-button>
                    <el-button type="warning" @click="exportPost">导出</el-button>
                </div>
                <div style="margin-top:20px;">
                    <el-table :data="postList" stripe style="width: 100%" :height="dialogMaxHeigh+'px'"> 
                        <!-- <el-table-column prop="TASKNAME" label="任务名称"></el-table-column> -->
                        <el-table-column prop="LANDNAME" label="宗地名称"></el-table-column>
                        <el-table-column prop="LZLandTypeName" label="土地用途"></el-table-column>
                        <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                        <el-table-column prop="REGION" label="省市区"></el-table-column>
                        <el-table-column prop="TOWN" label="镇(乡)"></el-table-column>
                        <el-table-column prop="VILLAGE" label="村"></el-table-column>
                        <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                        <!-- <el-table-column prop="ISHAVECERTIFICATE" label="有无证（批复）">
                            <template slot-scope="scope">
                                <span v-if="scope.row.ISHAVECERTIFICATE== 1">有</span>
                                <span v-else>无</span>
                            </template>
                        </el-table-column> -->
                        <el-table-column prop="OLDBHTYPE" label="证书类型"></el-table-column>
                        <el-table-column prop="OLDBH" label="证书编号">
                            <template slot-scope="scope">
                                <span v-if="scope.row.OLDBH">{{scope.row.OLDBH}}</span>
                                <span v-else></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="LZMJ" label="落宗面积"></el-table-column>
                        <el-table-column prop="OLDMJ" label="原面积">
                            <template slot-scope="scope">
                                <span v-if="scope.row.OLDMJ">{{scope.row.OLDMJ}}</span>
                                <span v-else></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OLDMJ" label="差额面积">
                            <template slot-scope="scope">
                                <span v-if="scope.row.OLDMJ">{{Math.abs((scope.row.LZMJ - scope.row.OLDMJ).toFixed(2))}}</span>
                                <span v-else></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="CHANGEINFO" label="面积变化原因">
                            <template slot-scope="scope">
                                <span v-if="scope.row.CHANGEINFO">{{scope.row.CHANGEINFO}}</span>
                                <span v-else></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                        <el-table-column prop="WorkUserName" label="提交人"></el-table-column>
                        <el-table-column prop="UpdateTime" label="提交时间"></el-table-column>
                        <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="taskPost(scope.row)" type="text" size="small" style="color:#409EFF">查看任务</el-button>
                        </template>
                    </el-table-column>
                    </el-table>
                    <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="统计表" name="b">
                <div style="margin-bottom:20px;">
                    <el-date-picker v-model="excelTimes" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="iwss"> </el-date-picker>
                    <el-button type="primary" @click="getCountTableDatas">查询</el-button>
                </div>
                <el-table :data="countData" style="width: 100%" :height="dialogMaxHeigh+'px'" v-if="activeName=='b'" empty-text="点击查询查看落宗统计表" show-summary>
                    <el-table-column  label="序号" width="90" align="center" fixed prop="xh"> </el-table-column>
                    <el-table-column  label="单位" align="center" fixed prop="dw"> </el-table-column>
                    <el-table-column  v-for="item in fallTypeColumnList"  :key="item.id" :label="item.name" align="center"> 
                        <el-table-column  label="有证/批复的土地" align="center"> 
                            <el-table-column  label="宗地数" width="80" align="center" :prop="item.yzsl"></el-table-column>
                            <el-table-column  label="面积（平方米）" width="150" align="center" :prop="item.yzmj"></el-table-column>
                        </el-table-column>
                        <el-table-column  label="无证/批复的土地" align="center"> 
                            <el-table-column  label="宗地数" width="80" align="center" :prop="item.wzsl"></el-table-column>
                            <el-table-column  label="面积（平方米）" width="150" align="center" :prop="item.wzmj"></el-table-column>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column  label="合计" align="center" fixed="right" width="250"> 
                        <el-table-column  label="宗地数" width="100" align="center" prop="sl" sortable></el-table-column>
                        <el-table-column  label="面积（平方米）" width="150" align="center" prop="mj" sortable></el-table-column>
                    </el-table-column>
                </el-table>
                <div class="tableBottomData">

                </div>
            </el-tab-pane>
        </el-tabs>
            
        <el-dialog title="已绑定任务" :visible.sync="FallBindModal" width="1300px" :close-on-click-modal="false" top="50px">
            <el-table :data="FpostList" stripe style="width: 100%" :height="fallTableHeight"> 
                    <el-table-column prop="TASKNAME" label="任务名称"></el-table-column>
                    <el-table-column prop="LANDNAME" label="宗地项目名称"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="WorkUserName" label="作业人员"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="FPageIndex" :page-size="FPageSize" :total="FTotal" class="pg" @current-change="FPGChange"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>

        <el-dialog title="导出落宗" :visible.sync="exportModal" width="800px" :close-on-click-modal="false">
            <el-form  status-icon  label-width="200px" class="demo-uForm">
                <el-form-item label="作业单位">
                    <el-checkbox-group v-model="checkUnits">
                        <el-checkbox v-for="item in zydwList" :label="item.SNOWID" :key="item.SNOWID">{{ item.DISPLAYNAME }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="二级单位">
                    <el-select v-model="exportEjdw" placeholder="请选择二级单位（选填）"  filterable clearable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="土地用途">
                    <el-select v-model="exportTdyt" placeholder="请选择土地用途（选填）"  filterable clearable style="width:100%;">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书类型">
                    <el-select v-model="exportZslx" placeholder="请选择证书类型（选填）"  filterable clearable style="width:100%;">
                        <el-option label="土地证" value="0" ></el-option>
                        <el-option label="批复" value="1" ></el-option>
                        <el-option label="无权源" value="2" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>  
                    <el-button :type="exportStatus?'danger':'warning'" @click="submitExport">{{exportStatus?'导出中，点击停止':'确定导出'}}</el-button>
                    <el-button @click="fallEditModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
var h = document.body.clientHeight
export default {
  name: "Fall",
  data() {    
    return {
        activeName:'a',
        canDo:true,
        showPagination:false,
        regions:"",
        options:[],
        cityProps:{
            value:'name',   
            label:'name',  
            children: 'children'   
        },
        cityPropsB:{
            value:'name',   
            label:'name',  
            children: 'children',
            checkStrictly:true,
        },

        fejdw:"",
        ejdwList:[],
        zdmc:"",
        zslx:"",
        zslxList:[],
        bdcdyh:'',
        name:"",
        tjr:"",
        tjrList:[],
        sfyz:"",
        mjsfyy:"",
        zdlx:"",
        zdlxList:[],
        zydwid:"",
        zydwList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        dialogMaxHeigh:(h-300),
        
        unitDataObject:{},
        fallTypeColumnList:[],

        excelTimes:[],
        countData:[],


        fallItem:null,
        fallTableHeight:0,
        FpostList:[],
        FPageIndex:1,
        FPageSize:10,
        FTotal:0,
        FallBindModal:false,


        exportModal:false,
        exportEjdw:"",
        exportZslx:"",
        exportTdyt:"",
        checkUnits:[],
        exportTimer:"",
        exportStatus:false,
    };
  },
  mounted() {
    this.fallTableHeight = h - 280
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name= getQueryData.taskName
        this.zydwid= getQueryData.submitworkunitsnowid
        this.tjr= getQueryData.submitworkusersnowid
        this.zdlx= getQueryData.lzlandtypesnowid
        this.sfyz= getQueryData.isHaveCertificate
        this.mjsfyy= getQueryData.mjIsInconsistent
        this.zdmc=getQueryData.LandName
        this.zslx=getQueryData.zstypesnowid
        this.fejdw=getQueryData.EJDW
        var region=[]
        if(getQueryData.region!=''){
            region=getQueryData.region.split("/")
        }
        this.region= region
        this.bdcdyh= getQueryData.BDCDYH
    }
    this.loadItem=null
    this.getPostList()
    this.getZdlx()
    this.getEjdwList()
    this.getZslxList()
    this.options=this.comjs.cityss
  },
  methods: {
    getZslxList(){
        this.$http.post("ZSType/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.zslxList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
                for(var i=0;i<this.zdlxList.length;i++){
                    var d=this.zdlxList[i]
                    var cd={id:d.SnowID,name:d.Name,yzsl:'yzsl'+i,yzmj:'yzmj'+i,wzsl:'wzsl'+i,wzmj:'wzmj'+i,sl:0,mj:0,}
                    this.fallTypeColumnList.push(cd)
                }
                this.getUnitList()
            })
    },
    getUnitList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.zydwList=this.sortArrayNewList(response.data.DATA)
                    for(var i=0;i<this.zydwList.length;i++){
                        var dw=this.zydwList[i]
                        var dataitem={xh:(i+1),dw:dw.DISPLAYNAME,dwid:dw.SNOWID,sl:0,mj:0,}
                        for(var j=0;j<this.fallTypeColumnList.length;j++){
                            dataitem['yzsl'+j]=0
                            dataitem['yzmj'+j]=0
                            dataitem['wzsl'+j]=0
                            dataitem['wzmj'+j]=0
                        }
                        this.countData.push(dataitem)
                    }
                    this.getCountTableDatas()
                }
            })
    },
    getFallTypeIndexById(id){
        var index=0
        for(var i=0;i<this.fallTypeColumnList.length;i++){
            if(id==this.fallTypeColumnList[i].id){
                index=i
                break;
            }
        }
        return index
    },
    
    getCountTableDatas(){
        var st=''
        var et=''
        if(this.excelTimes.length==2){
            st=this.comjs.getDateMinStrByDate(this.excelTimes[0])+" 00:00:00"
            et=this.comjs.getDateMinStrByDate(this.excelTimes[1])+" 00:00:00"
        }
        this.$http.post("Count/GetLZTypeDetailCount", {startTime:st,endTime:et,})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var dataObject={}
                    for(var i=0;i<list.length;i++){
                        var dw=list[i]
                        dataObject[dw.WorkUnitSnowID]={}
                        var sl=0
                        var mj=0

                        for(var j=0;j<dw.DetailList.length;j++){
                            var lx=dw.DetailList[j]
                            var index=this.getFallTypeIndexById(lx.LZLandTypeSnowID)
                            dataObject[dw.WorkUnitSnowID]['yzsl'+index]=lx.HaveCertificateCount
                            sl+=lx.HaveCertificateCount
                            dataObject[dw.WorkUnitSnowID]['yzmj'+index]=lx.HaveArea
                            mj+=lx.HaveArea
                            dataObject[dw.WorkUnitSnowID]['wzsl'+index]=lx.NotHaveCertificateCount
                            sl+=lx.NotHaveCertificateCount
                            dataObject[dw.WorkUnitSnowID]['wzmj'+index]=lx.NotHaveArea
                            mj+=lx.NotHaveArea
                        }
                        dataObject[dw.WorkUnitSnowID]['sl']=sl
                        dataObject[dw.WorkUnitSnowID]['mj']=mj
                    }
                    var nl=[]
                    for(var k=0;k<this.countData.length;k++){
                        var cd=this.countData[k]
                        if(dataObject[cd.dwid] != undefined){
                            for(var item in dataObject[cd.dwid]){
                                cd[item]=dataObject[cd.dwid][item]
                            }
                        }
                        nl.push(cd) 
                    }
                    this.countData=nl
                }
            })
    },
    settjrList(e){
        this.tjr=""
        this.tjrList=[]
        if(e!=''){
            this.$http.post("WorkUser/GetDataList", {parentSnowID:e})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.tjrList=response.data.DATA
                }
            })
        }
    },
    sortArrayNewList(list){
        var arr = new Array(list.length);
        for(var i=0;i<list.length;i++){
            var name=list[i].DISPLAYNAME
            if(name.indexOf("辽宁地质勘查")!= -1){
                arr[0]=list[i]
            }else if(name.indexOf("无锡")!= -1){
                arr[1]=list[i]
            }else if(name.indexOf("南京地正")!= -1){
                arr[2]=list[i]
            }else if(name.indexOf("山东")!= -1){
                arr[3]=list[i]
            }else if(name.indexOf("北京")!= -1){
                arr[4]=list[i]
            }else if(name.indexOf("中地云")!= -1){
                arr[5]=list[i]
            }else if(name.indexOf("盘锦恒信")!= -1){
                arr[6]=list[i]
            }else if(name.indexOf("盘锦北阳")!= -1){
                arr[7]=list[i]
            }else if(name.indexOf("大连")!= -1){
                arr[8]=list[i]
            }else if(name.indexOf("盘锦宏源")!= -1){
                arr[9]=list[i]
            }else if(name.indexOf("盘锦恒岳")!= -1){
                arr[10]=list[i]
            }else if(name.indexOf("安徽")!= -1){
                arr[11]=list[i]
            }else if(name.indexOf("辽宁地矿")!= -1){
                arr[12]=list[i]
            }else if(name.indexOf("核工")!= -1){
                arr[13]=list[i]
            }else if(name.indexOf("盘锦荣泰")!= -1){
                arr[14]=list[i]
            }
        }
        return arr
    },
    getPostList(){
        this.showLoading("请稍后")
        var region=''
        if(this.regions && this.regions.length>0){
            for(var i=0;i<this.regions.length;i++){
                if(i==0){
                    region=this.regions[i]
                }else{
                    region=region+"/"+this.regions[i]
                }
            }
        }
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            taskName:this.name,
            submitworkunitsnowid:this.zydwid,
            submitworkusersnowid:this.tjr,
            LZLandTypeSnowID:this.zdlx,
            isHaveCertificate:this.sfyz,
            mjIsInconsistent:this.mjsfyy,
            region:region,
            BDCDYH:this.bdcdyh,
            EJDW:this.fejdw,
            LandName:this.zdmc,
            zstypesnowid:this.zslx,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("LandLZ/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },


    taskPost(item){
        this.fallItem=item
        this.FpageIndex=1
        this.searchFallBindTaskList()
        this.FallBindModal=true
    },
    searchFallBindTaskList(){
        this.canDo=false
        this.showLoading("请稍后")
        var params={
            lzSnowID:this.fallItem.SnowID,
            pageIndex:this.FpageIndex,
            pageSize:this.FpageSize,
        }
        this.$http.post("LandLZ/GetLZBindDetail", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.FpostList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                } else {
                    this.FpostList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    FPGChange(e){
        this.FpostList=[]
        this.FPageIndex=e
        this.FTotal=0
        this.searchFallBindTaskList()
    },


    exportPost(){
        this.exportModal=true
    },
    submitExport(){
        if(this.canDo){
            if(this.exportStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatus=false
                    this.exportTimer=new Date().getTime().toString()
                })
               
            }else{
                if(this.checkUnits.length===0){
                    this.$message.error("请至少选择一个作业单位")
                }else{
                    this.exportTimer=new Date().getTime().toString()
                    this.sendExport(this.exportTimer)
                }
            }
            
        }

    },
    sendExport(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                workUnitSnowIDList:this.checkUnits,
                ejdw:this.exportEjdw,
                oldBHType:this.exportZslx,
                lzLandTypeSnowID:this.exportTdyt,
            }
            this.$download.post("ExportExcel/ExportLZList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName()
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".zip")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".zip"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                })
                .catch((error) => {
                    this.hideLoading()
                });
            }
    },
    getZydwStrById(id){
        var name=''
        for(var i=0;i<this.zydwList.length;i++){
            if(this.zydwList[i].SNOWID == id){
                name=this.zydwList[i].DISPLAYNAME
                break;
            }
        }
        return name
    },
    getTdytStrById(id){
        var name=''
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID == id){
                name=this.zdlxList[i].Name
                break;
            }
        }
        return name
    },
    getZslxStrById(lx){
        var name=''
        if(lx=='0'){
            name='土地证'
        }else if(lx=='1'){
            name='批复'
        }else if(lx=='2'){
            name='无权源'
        }
        return name
    },
    getFileName(){
        var name=""
        for(var i=0;i<this.checkUnits.length;i++){
            var dwname=this.getZydwStrById(this.checkUnits[i])
            if(i==0){
                name=dwname
            }else{
                name+="_"+dwname
            }
        }



        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+="_"+t

        var option=''
        if(this.exportEjdw !=''){
            option="("+this.exportEjdw
        }
        if(this.exportTdyt !=''){
            var tdytname=this.getTdytStrById(this.exportTdyt)
            if(option.indexOf("(") != -1){
                option+='、'+tdytname
            }else{
                option="("+tdytname
            }
        }
        if(this.exportZslx!=''){
            var zslxname=this.getZslxStrById(this.exportZslx)
            if(option.indexOf("(") != -1){
                option+='、'+zslxname
            }else{
                option="("+zslxname
            }
        }
        if(option!=''){
            option+=")"
        }
        name+=option+"落宗数据导出"


        return name
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
  .fallDetailBox{overflow: hidden;margin: 20px;font-size: 14px;color: #333333;}
  .fallDetailBox .fallDetailTopBox{overflow: hidden;background-color: #EEECE1;font-weight: bold;}
  .fallDetailBox .fallDetailColumn{float:left;border-left: 1px solid #333333;border-top: 1px solid #333333;text-align: center;overflow: hidden;}
  .fallTopColumn_xh{height: 90px;line-height: 90px;border-bottom: 1px solid #333333;width: 70px;}
  .fallTopColumn_dw{height: 90px;line-height: 90px;border-bottom: 1px solid #333333;width: 240px;}
  .fallTopColumn_lx{width: 400px;}
  .fallTopColumn_zj{width: 200px;}
  .fallTopColumn_zj .fallTopTitle{height: 59px;line-height: 59px;border-bottom: 1px solid #333333;}
  .fallColumnRow{height: 29px;line-height: 29px;border-bottom: 1px solid #333333;float:left;}
  .fallTopTitle{height: 29px;line-height: 29px;border-bottom: 1px solid #333333;font-size: 15px;float:left;width: 100%;}
  .fallColumnBorderRight{border-right: 1px solid #333333;}
  .fallColumnBorderBottom{border-bottom: 1px solid #333333;}

</style>
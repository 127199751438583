import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
//数据汇总
import DataView from '../views/DataView.vue'
//用户管理
import AdminUserManage from '../views/UserManage/AdminUserManage.vue'
import WorkUserMacList from '../views/UserManage/WorkUserMacList.vue'
import PositionManage from '../views/UserManage/PositionManage.vue'
import UnitManage from '../views/UserManage/UnitManage.vue'
import WorkUserList from '../views/UserManage/WorkUserList.vue'
import OilUserManage from '../views/UserManage/OilUserManage.vue'

//基础数据
import LandManage from '../views/DataBase/LandManage.vue'
import FruitManage from '../views/DataBase/FruitManage.vue'
import OilWellManage from '../views/DataBase/OilWellManage.vue'
import RegionManage from '../views/DataBase/RegionManage.vue'
import LandTypeManage from '../views/DataBase/LandTypeManage.vue'
import FallTypeManage from '../views/DataBase/FallTypeManage.vue'
import CardTypeManage from '../views/DataBase/CardTypeManage.vue'
import CantonManage from '../views/DataBase/CantonManage.vue'
import DegreeManage from '../views/DataBase/DegreeManage.vue'
//任务管理
import LandTaskManage from '../views/TaskManage/LandTaskManage.vue'
import FallList from '../views/TaskManage/FallList.vue'
import OilWellTaskManage from '../views/TaskManage/OilWellTaskManage.vue'


import store from '../store/index'
import cookies from 'vue-cookies'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      //数据汇总
      {
        path: '/DataView',
        name: 'DataView',
        components: {DataView}
      },
      //用户管理
      {
        path: '/AdminUserManage',
        name: 'AdminUserManage',
        components: {AdminUserManage}
      },
      {
        path: '/RegionManage',
        name: 'RegionManage',
        components: {RegionManage}
      },
      {
        path: '/PositionManage',
        name: 'PositionManage',
        components: {PositionManage}
      },
      {
        path: '/UnitManage',
        name: 'UnitManage',
        components: {UnitManage}
      },
      {
        path: '/WorkUserList',
        name: 'WorkUserList',
        components: {WorkUserList}
      },
      {
        path: '/WorkUserMacList',
        name: 'WorkUserMacList',
        components: {WorkUserMacList}
      },
      {
        path: '/OilUserManage',
        name: 'OilUserManage',
        components: {OilUserManage}
      },
      //基础数据
      {
        path: '/LandManage',
        name: 'LandManage',
        components: {LandManage}
      },
      {
        path: '/FruitManage',
        name: 'FruitManage',
        components: {FruitManage}
      },
      {
        path: '/OilWellManage',
        name: 'OilWellManage',
        components: {OilWellManage}
      },
      {
        path: '/LandTypeManage',
        name: 'LandTypeManage',
        components: {LandTypeManage}
      },
      {
        path: '/FallTypeManage',
        name: 'FallTypeManage',
        components: {FallTypeManage}
      },
      {
        path: '/CardTypeManage',
        name: 'CardTypeManage',
        components: {CardTypeManage}
      },
      {
        path: '/CantonManage',
        name: 'CantonManage',
        components: {CantonManage}
      },
      {
        path: '/DegreeManage',
        name: 'DegreeManage',
        components: {DegreeManage}
      },
      //任务管理
      {
        path: '/LandTaskManage',
        name: 'LandTaskManage',
        components: {LandTaskManage}
      },
      {
        path: '/FallList',
        name: 'FallList',
        components: {FallList}
      },
      {
        path: '/OilWellTaskManage',
        name: 'OilWellTaskManage',
        components: {OilWellTaskManage}
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log("执行路由")
  var initRouter= store.state.initRouter
  if(initRouter == ""){
    if(to.name!= "Login"){
      store.commit('setOpenPage', to.name);
    }
    store.commit('setInitRouter', "1");
    router.replace("/")
  }else{
    next(true);
  }
});

router.afterEach((to) => {
  //////console.log(to)
});
export default router

<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">油田用户管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入用户姓名" class="iw" ></el-input>
                <el-input v-model="lname" placeholder="请输入登录名" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="DISPLAYNAME" label="用户姓名"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="PHONE" label="手机号"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="ejdwPost(scope.row)" type="text" size="small" style="color:#409EFF">配置二级单位</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="用户姓名" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="loginname">
                    <el-input v-model="postInfo.loginname" autocomplete="off" :disabled="doType=='2'"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="doType=='1'">
                    <el-input v-model="postInfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="postInfo.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="ejdwModal" width="1200px" :close-on-click-modal="false">
            <el-form  label-width="100px" class="demo-uForm">
                <el-form-item label="二级单位">
                    <!-- <el-select v-model="ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;" multiple>
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" clearable></el-option>
                    </el-select> -->
                    <el-checkbox-group v-model="ejdw">
                        <el-checkbox v-for="item in ejdwList" :label="item" :key="item">{{ item }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>  
                    <el-button  @click="checkEjdwSelect()" style="float:right;">全选/全不选</el-button>
                    <el-button type="primary" @click="submitEjdwForm()">提交</el-button>
                    <el-button @click="postModal = ejdwModal">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "OilUserManage",
  data() {
    var displayname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用户姓名"));
      } else {
        callback();
      }
    };
    var loginname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入登录名"));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var phone = (rule, value, callback) => {
      if (value === '' || value == null) {
        callback(new Error('请输入手机号'));
      } else if(!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('请输入正确的手机号'));
      }else {
        callback();
      }
    };
    
    return {
        
        canDo:true,
        showPagination:false,
        name:"",
        lname:"",

        ejdwList:[],
        ejdw:[],
        nowOilUser:null,
        ejdwModal:false,

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            displayname: [{ validator: displayname, trigger: "blur" }],
            loginname: [{ validator: loginname, trigger: "blur" }],
            phone: [{ validator: phone, trigger: "blur" }],
            password: [{ validator: password, trigger: "blur" }],
        },


    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.lname=getQueryData.loginName
    }
    this.loadItem=null
    this.getPostList()
    this.getEjdwList()
  },
  methods: {
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            loginName:this.lname,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("OilUser/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.postInfo={
            displayname:"",
            loginname:"",
            phone:"",
            password:"",
        }
        this.doTypeStr="添加油田用户"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("OilUser/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SNOWID,
            displayname:item.DISPLAYNAME,
            loginname:item.LOGINNAME,
            phone:item.PHONE,
            password:item.PASSWORD,
        }
        this.doTypeStr="修改油田用户"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("OilUser/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },

    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除用户：'+item.DISPLAYNAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("OilUser/DeleteData", {SnowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
    },
    ejdwPost(item){
        this.doTypeStr=item.DISPLAYNAME+"-二级单位分配"
        this.ejdw=[]
        this.nowOilUser=item
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("OilUser/GetOilUserEJDW", {oilUserSnowID:item.SNOWID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                this.ejdwModal=true
                if (response.data.SUCCESS) {
                    if(response.data.DATA.length>0){
                        this.ejdw=response.data.DATA[0].EJDWSTR.split(",")
                    }
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.canDo=true  
                this.ejdwModal=true
            });
    },
    submitEjdwForm(){
        if(this.canDo){
            var str=""
            for(var i=0;i<this.ejdw.length;i++){
                if(i==0){
                    str=this.ejdw[i]
                }else{
                    str+=","+this.ejdw[i]
                }
            }
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("OilUser/SetOilUserEJDW", {oilUserSnowID:this.nowOilUser.SNOWID,ejdwstr:str,})
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("设置成功")
                        this.ejdwModal=false
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true  
                    if (error.isAxiosError) {
                        if(error.response.status==401){
                            this.logout()
                        }
                    } 
                });
        }
    },
    checkEjdwSelect(){
        if(this.ejdw.length<this.ejdwList.length){
            this.ejdw=this.ejdwList
        }else{
            this.ejdw=[]
        }
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>
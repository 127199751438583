<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">地块管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入宗地项目名称" class="iw" ></el-input>
                <el-input v-model="code" placeholder="请输入不动产证书编号" class="iw" ></el-input>
                <el-select v-model="cqid" placeholder="选择采区"  filterable  clearable class="iw">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="ejdw" placeholder="选择二级单位"  filterable  clearable  allow-create class="iw">
                    <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" >添加</el-button>
                
                <el-dropdown class="fr">
                    <el-button type="primary">
                        生成任务<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="createTask">一键自动生成</el-dropdown-item>
                        <el-dropdown-item @click.native="selectLandCreate">勾选地块生成</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button type="warning" @click="exportPost" class="fr" style="margin-right:20px;">导入地块</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" @selection-change="handleSelectionChange"> 
                    <el-table-column type="selection" width="60"></el-table-column>
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <div class="rowExpand">
                                <div label="" style="margin-right:20px;">
                                    <span>地块代码：{{scope.row.CODE}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span v-if="scope.row.DKLB== '1'">地块类别：井</span>
                                    <span v-else-if="scope.row.DKLB== '2'">地块类别：站</span>
                                    <span v-else-if="scope.row.DKLB== '3'">地块类别：矿区</span>
                                    <span v-else-if="scope.row.DKLB== '4'">地块类别：进井路</span>
                                    <span v-else>地块类别：/</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>小地名：{{scope.row.XDM}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>批准用途：{{scope.row.PZYT}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>批准文号：{{scope.row.PZWH}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>批准面积：{{scope.row.PZMJ}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>批准日期：{{scope.row.PZRQ}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>井口数量：{{scope.row.JKSL}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>采区名称：{{scope.row.CQMC}}</span>
                                </div>
                                <!-- <div label="" style="margin-right:20px;">
                                    <span v-if="scope.row.LYZT1== '1'">利用状态1：在用</span>
                                    <span v-else-if="scope.row.LYZT1== '2'">利用状态1：长停</span>
                                    <span v-else-if="scope.row.LYZT1== '3'">利用状态1：废弃</span>
                                    <span v-else>利用状态1：/</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span v-if="scope.row.LYZT2== '1'">利用状态2：正常</span>
                                    <span v-else-if="scope.row.LYZT2== '2'">利用状态2：限制</span>
                                    <span v-else-if="scope.row.LYZT2== '3'">利用状态2：部分耕种</span>
                                    <span v-else-if="scope.row.LYZT2== '4'">利用状态2：已耕种</span>
                                    <span v-else-if="scope.row.LYZT2== '5'">利用状态2：复垦</span>
                                    <span v-else-if="scope.row.LYZT2== '6'">利用状态2：部分违建</span>
                                    <span v-else-if="scope.row.LYZT2== '7'">利用状态2：全部违建</span>
                                    <span v-else-if="scope.row.LYZT2== '8'">利用状态2：其他占用</span>
                                    <span v-else>利用状态2：/</span>
                                </div> -->
                                <div label="" style="margin-right:20px;">
                                    <span v-if="scope.row.KTZDL== '1'">可调整地类：水田</span>
                                    <span v-else-if="scope.row.KTZDL== '2'">可调整地类：旱田</span>
                                    <span v-else-if="scope.row.KTZDL== '3'">可调整地类：建设用地</span>
                                    <span v-else>可调整地类：/</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>土地等级：{{scope.row.TDDJ}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>耕地等级：{{scope.row.GDDJ}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>面积（平方米）：{{scope.row.MJ}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>登记面积：{{scope.row.DJMJ}}</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span v-if="scope.row.DJZT== '1'">登记状态：是否抵押</span>
                                    <span v-else-if="scope.row.DJZT== '2'">登记状态：查封</span>
                                    <span v-else>登记状态：/</span>
                                </div>
                                <div label="" style="margin-right:20px;">
                                    <span>使用权类型：{{scope.row.SYQLX}}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ZSBH" label="不动产证书编号" width="120" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="宗地项目名称" width="120" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="PROVINCES" label="省"></el-table-column>
                    <el-table-column prop="CITY" label="市"></el-table-column>
                    <el-table-column prop="DISTRICT" label="区"></el-table-column>
                    <el-table-column prop="JTZL" label="具体坐落"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="MJ_M" label="面积（亩）" width="100"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号（地号）"></el-table-column>
                    <el-table-column prop="QLLX" label="权利类型"></el-table-column>
                    <el-table-column prop="ZDWJH" label="征地文件号"></el-table-column>
                    <el-table-column prop="SYQX" label="使用期限"></el-table-column>
                    <el-table-column prop="FZRQ" label="发证日期"></el-table-column>
                    <el-table-column prop="SFYZDDT" label="是否有宗地地图">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SFYZDDT== '1'">是</span>
                            <span v-else-if="scope.row.SFYZDDT== '0'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SFYZB" label="是否有坐标">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SFYZB== '1'">是</span>
                            <span v-else-if="scope.row.SFYZB== '0'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ZZTDSYQXZ" label="证载土地使用权性质" width="150"></el-table-column>
                    <el-table-column prop="ZZTDYT" label="证载土地用途" width="120"></el-table-column>
                    <el-table-column prop="TDXYT" label="土地现用途" width="100"></el-table-column>
                    <el-table-column prop="LYZT2" label="占地用途" width="100"></el-table-column>
                    <el-table-column prop="YDSJ" label="用地时间"></el-table-column>
                    <el-table-column prop="SFYDFZFDJ" label="是否已与地方政府对接" width="160">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SFYDFZFDJ== '1'">是</span>
                            <span v-else-if="scope.row.SFYDFZFDJ== '0'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SFYWCBG" label="是否已完成变更" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SFYWCBG== '1'">是</span>
                            <span v-else-if="scope.row.SFYWCBG== '0'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="BGHBDCZH" label="变更后不动产证号（已完成变更的填写）" width="300"></el-table-column>
                    <el-table-column prop="SFKBG" label="是否可变更" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SFKBG== '1'">是</span>
                            <span v-else-if="scope.row.SFKBG== '0'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SFYTJBGSQJXGZL" label="是否已提交变更申请及相关资料"  width="220">
                        <template slot-scope="scope">
                            <span v-if="scope.row.SFYTJBGSQJXGZL== '1'">是</span>
                            <span v-else-if="scope.row.SFYTJBGSQJXGZL== '0'">否</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WCBGSJ" label="完成或预计完成变更时间（202X年XX月）" width="300"></el-table-column>
                    <el-table-column prop="BKBGYY" label="如不可变更，请描述原因" width="180"></el-table-column>
                    <el-table-column prop="BGJZ" label="未完成变更的，请简要描述进展" width="240"></el-table-column>
                    <el-table-column prop="BZ" label="备注"  width="200"></el-table-column>
                    <el-table-column label="操作" width="120" fixed="right">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                            <el-button @click="tianArea(scope.row)" type="text" size="small" style="color:#409EFF">区域</el-button>
                            <!-- <span><el-button @click="bindWell(scope.row)" type="text" size="small" style="color:#409EFF">关联井口</el-button></span>
                            <el-button @click="fruitSubmit(scope.row)" type="text" size="small" style="color:#67C23A">成果汇交</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" top="20px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="280px" class="demo-uForm">
                <el-form-item label="宗地项目名称" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="土地证（不动产证）号" prop="zsbh">
                    <el-input v-model="postInfo.zsbh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="省市区">
                    <el-cascader v-model="addr" ref="cascaderAddr" :options="options" :props="cityProps"  placeholder="请选择省市区" @change="handleAddrChange" style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="具体坐落">
                    <el-input v-model="postInfo.JTZL" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="面积（亩）" prop="mJ_M">
                    <el-input v-model="postInfo.mJ_M" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="postInfo.mj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="二级单位">
                    <el-input v-model="postInfo.ejdw" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用权类型">
                    <el-input v-model="postInfo.syqlx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发证日期">
                    <el-date-picker v-model="postInfo.fzrq" type="date" placeholder="选择发证日期" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="批准日期">
                    <el-date-picker v-model="postInfo.pzrq" type="date" placeholder="选择批准日期" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="批准用途">
                    <el-input v-model="postInfo.pzyt" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="批准文号">
                    <el-input v-model="postInfo.pzwh" autocomplete="off"></el-input>
                </el-form-item>
                <!-- prop="pzmj" -->
                <el-form-item label="批准面积">
                    <el-input v-model="postInfo.pzmj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号（地号）">
                    <el-input v-model="postInfo.bdcdyh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利类型">
                    <el-input v-model="postInfo.qllx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="征地文件号">
                    <el-input v-model="postInfo.zdwjh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用期限">
                    <el-date-picker v-model="postInfo.syqx" type="date" placeholder="选择使用期限" style="width:100%"></el-date-picker>
                </el-form-item>
                
                <el-form-item label="是否有宗地地图">
                    <el-radio v-model="postInfo.sfyzddt" label="1">是</el-radio>
                    <el-radio v-model="postInfo.sfyzddt" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="是否有坐标">
                    <el-radio v-model="postInfo.sfyzb" label="1">是</el-radio>
                    <el-radio v-model="postInfo.sfyzb" label="0">否</el-radio>
                </el-form-item>

                <el-form-item label="证载土地使用权性质">
                    <el-input v-model="postInfo.zztdsyqxz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="证载土地用途">
                    <el-input v-model="postInfo.zztdyt" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="土地现用途">
                    <el-input v-model="postInfo.tdxyt" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="占地用途">
                    <el-input v-model="postInfo.lyzT2" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用地时间">
                    <el-date-picker v-model="postInfo.YDSJ" type="date" placeholder="选择用地时间" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="是否已与地方政府对接">
                    <el-radio v-model="postInfo.sfydfzfdj" label="1">可用</el-radio>
                    <el-radio v-model="postInfo.sfydfzfdj" label="0">不可用</el-radio>
                </el-form-item>
                <el-form-item label="是否已完成变更">
                    <el-radio v-model="postInfo.sfywcbg" label="1">可用</el-radio>
                    <el-radio v-model="postInfo.sfywcbg" label="0">不可用</el-radio>
                </el-form-item>
                
                <el-form-item label="变更后不动产证号（已完成变更的填写）">
                    <el-input v-model="postInfo.bghbdczh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否可变更">
                    <el-radio v-model="postInfo.sfkbg" label="1">可用</el-radio>
                    <el-radio v-model="postInfo.sfkbg" label="0">不可用</el-radio>
                </el-form-item>
                <el-form-item label="是否已提交变更申请及相关资料">
                    <el-radio v-model="postInfo.sfytjbgsqjxgzl" label="1">可用</el-radio>
                    <el-radio v-model="postInfo.sfytjbgsqjxgzl" label="0">不可用</el-radio>
                </el-form-item>
                <el-form-item label="完成或预计完成变更时间（202X年XX月）">
                    <el-input v-model="postInfo.wcbgsj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="如不可变更，请描述原因">
                    <el-input v-model="postInfo.bkbgyy" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="未完成变更的，请简要描述进展">
                    <el-input v-model="postInfo.bgjz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="postInfo.bz" autocomplete="off"></el-input>
                </el-form-item>


                <el-form-item label="地块代码">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地块类别">
                    <el-select v-model="postInfo.dklb" placeholder="请选择地块类别"  filterable  style="width:100%;">
                        <el-option label="1" value="井"></el-option>
                        <el-option label="2" value="站"></el-option>
                        <el-option label="3" value="矿区"></el-option>
                        <el-option label="4" value="进井路"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="小地名">
                    <el-input v-model="postInfo.xdm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="采区名称">
                    <el-select v-model="postInfo.cqsnowid" placeholder="请选择采区名称"  filterable clearable style="width:100%;">
                        <el-option v-for="item in cqList" :key="item.SNOWID" :label="item.NAME" :value="item.SNOWID"></el-option>
                    </el-select>
                </el-form-item>
               
                <!-- <el-form-item label="利用状态1">
                    <el-select v-model="postInfo.lyzT1" placeholder="请选择利用状态1"  filterable  style="width:100%;">
                        <el-option value="1" label="在用"></el-option>
                        <el-option value="2" label="长停"></el-option>
                        <el-option value="3" label="废弃"></el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label="利用状态2">
                    <el-select v-model="postInfo.lyzT2" placeholder="请选择利用状态2"  filterable  style="width:100%;">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="闲置"></el-option>
                        <el-option value="3" label="部分耕种"></el-option>
                        <el-option value="4" label="已耕种"></el-option>
                        <el-option value="5" label="复垦"></el-option>
                        <el-option value="6" label="部分违建"></el-option>
                        <el-option value="7" label="全部违建"></el-option>
                        <el-option value="8" label="其他占用"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="可调整地类">
                    <el-select v-model="postInfo.ktzdl" placeholder="请选择可调整地类"  filterable  style="width:100%;">
                        <el-option value="1" label="水田"></el-option>
                        <el-option value="2" label="旱地"></el-option>
                        <el-option value="3" label="建设用地"></el-option>
                    </el-select>
                </el-form-item>
                <!-- prop="tddj" -->
                <el-form-item label="土地等级">
                    <el-input v-model="postInfo.tddj" autocomplete="off"></el-input>
                </el-form-item>
                <!-- prop="gddj" -->
                <el-form-item label="耕地等级">
                    <el-input v-model="postInfo.gddj" autocomplete="off"></el-input>
                </el-form-item>
       
                <!-- prop="djmj" -->
                <el-form-item label="登记面积">
                    <el-input v-model="postInfo.djmj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登记状态">
                    <el-select v-model="postInfo.djzt" placeholder="请选择登记状态"  filterable  style="width:100%;">
                        <el-option value="1" label="是否抵押"></el-option>
                        <el-option value="2" label="查封"></el-option>
                    </el-select>
                </el-form-item>
  
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="范围设置" :visible.sync="tianmapModal" width="1100px" top="30px" :close-on-click-modal="false" :close-on-press-escape="false"> 
            <TianMap class="map" ref="tianmap" :width="mapWidth" :height="mapHeight" @mapFinish="tianmapFinish"  :move="false" :draw="true"></TianMap>
            <div style="margin: 20px 0;">
                <el-button type="success" @click="startTDraw()" class="fl">绘制区域</el-button>
                <el-button type="danger" @click="delTDraw()" class="fl mr20">删除区域</el-button>
                <el-input placeholder="请在此处粘贴坐标字符串"  autocomplete="off" v-model="polygonStr" style="width:620px;" class="fl mr20" @keyup.enter.native="setMapPolygon()"><i slot="suffix" class="el-input__icon el-icon-finished" style="cursor: pointer;" @click="setMapPolygon()"></i></el-input>
                <el-button @click="tianmapModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="submitTianForm()" class="fr ml20">保存</el-button>
            </div>
            <div style="height:40px;"></div>
        </el-dialog>
        <el-dialog title="绑定井口" :visible.sync="bindModal" width="1100px" top="30px" :close-on-click-modal="false">
            <div style="margin: 20px 0;overflow:hidden;">
                <el-checkbox-group v-model="bindDatas.WellSnowIDList" size="small">
                    <el-checkbox v-for="item in wellList" :key="item.SNOWID" :label="item.SNOWID" border style="margin-right:2px;">{{item.DISPLAYNAME}}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div style="height:40px;">
                <el-button @click="bindModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="sendBindWell()" class="fr ml20">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="选择文件" :visible.sync="chooseFileModal" width="400px"  :append-to-body="true">
            <el-upload
              ref="uploadLand"
              class="upload-demo"
              drag
              :multiple="false"
              :limit="1"
              accept=".xls,.xlsx"
              action=""
              :http-request="uploadFun"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules"  ref="fForm" label-width="200px" class="demo-uForm">
                <el-form-item label="地块名称">
                    <p>{{friuitLandName}}</p>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状"  filterable  style="width:100%;">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位"  filterable  style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-input v-model="fruitInfo.gyqk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-input v-model="fruitInfo.qllx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-input v-model="fruitInfo.qlxz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-input v-model="fruitInfo.yt" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="使用期限" prop="syqx">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdw">
                    <el-select v-model="fruitInfo.fzdw" placeholder="请选择发证地区"  filterable allow-create style="width:100%;"> 
                        <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="证书扫描件上传" prop="smj">
                    <el-upload
                        class="avatar-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :http-request="uploadFunC"
                        :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName!= ''" style="color:#409EFF;">{{smjFileName}}</span>
                            <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="smjFileName!= ''" type="danger" size="mini" @click="deleteFileC()">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload
                        class="avatar-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :http-request="uploadFunD"
                        :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName!=''" style="color:#409EFF;">{{cadFileName}}</span>
                            <el-button  v-else type="primary" size="mini">选择文件</el-button>
                    </el-upload>
                    <el-button  v-if="cadFileName!=''" type="danger" size="mini" @click="deleteFileD()">取消选择</el-button>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitFruitForm()">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                    <el-button type="danger" @click="delFruit()" v-if="doType=='2'">删除</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import TianMap from "../../components/OpenLayers"
export default {
  name: "PostManage",
  components:{
    TianMap,
  },
  data() {
    var displayname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入宗地项目名称"));
      } else {
        callback();
      }
    };
    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入地块代码"));
      } else {
        callback();
      }
    };
    var pzmj = (rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
            callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        } else {
            callback();
        }
    };
    var tddj= (rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
            callback(new Error('土地等级只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var gddj= (rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
            callback(new Error('耕地等级只能是数字'));
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于等于0的整数'));
        }else {
          callback();
        }
    };
    var mj=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var djmj=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
             callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };

    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    

    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入发证地区"));
      } else {
        callback();
      }
    };


    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传证书扫描件"));
      } else {
        callback();
      }
    };
    return {
        addr:[],
        options: [],
        cityProps:{
            value:'name',   // 指定选项的 值 为选项对象的某个属性值
            label:'name',   // 指定选项 标签 为选项对象的某个属性值	
            children: 'children'    //指定选项的 子选项 为选项对象的某个属性值
        },

        canDo:true,
        showPagination:false,
        name:"",
        code:"",
        cqid:"",

        ejdw:"",

        cqList:[],
        wellList:[],

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            displayname: [{ validator: displayname, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            pzmj: [{ validator: pzmj, trigger: "blur" }],
            tddj: [{ validator: tddj, trigger: "blur" }],
            gddj: [{ validator: gddj, trigger: "blur" }],
            mj: [{ validator: mj, trigger: "blur" }],
            mJ_M: [{ validator: mJ_M, trigger: "blur" }],
            djmj: [{ validator: djmj, trigger: "blur" }],
        },

        mapWidth:1060,
        mapHeight:600,
        tianmapModal:false,
        polygonStr:"",
        tianmap:null,

        bindModal:false,
        bindDatas:{},

        exportTable:[],
        exportTableModal:false,
        chooseFileModal:false,

        selectIds:[],


        ejdwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdw: [{ validator: fzdw, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
        },
        friuitLandName:"",

        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,
    };
  },
  mounted() {
    this.options=this.comjs.citys
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.code=getQueryData.code
        this.cqid=getQueryData.CQSNOWID
    }
    this.loadItem=null
    this.getCQList()
    this.getWellList()
    this.getPostList()
    this.getEjdwList()
    this.getFzdwList()
  },
  methods: {
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    handleSelectionChange(val) {
        this.selectIds=[]
        for(var i=0;i<val.length;i++){
            this.selectIds.push(val[i].SNOWID)
        }
    },
    selectLandCreate(){
        if(this.canDo){
            if(this.selectIds.length==0){
                this.$message.error("请先在列表中勾选地块")
            }else{
                this.sendCreateTask(this.selectIds)
            }
        }
    },
    createTask(){
        if(this.canDo){
            console.log(this.selectIds)
            this.sendCreateTask([])
        }
    },
    sendCreateTask(ids){
        this.showLoading("请稍后")
        this.canDo=false
        this.$http.post("Task/OneCreateLandTask", ids)
            .then((response) => {
                this.hideLoading()
                this.canDo=true
                if (response.data.SUCCESS) {
                    this.$confirm('是否跳转到任务管理中查看？', '生成结束', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$router.replace("/LandTaskManage");
                    }).catch(() => {
                        //
                    });
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.canDo=true
                this.$message.error("请求失败，请稍后再试")
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });   
    },

    handleAddrChange(e){
        let addrNode = this.$refs['cascaderAddr'].getCheckedNodes()[0]
        for(var i=0;i<addrNode.pathLabels.length;i++){
            if(i==0){
                this.postInfo.provinces=addrNode.pathLabels[i]
            }else if(i==1){
                this.postInfo.city=addrNode.pathLabels[i]
            }else if(i==2){
                this.postInfo.district=addrNode.pathLabels[i]
                break;
            }
        }
    },
    tianmapFinish(){
        this.tianmap=this.$refs.tianmap.map
    },
    getCQList(){
        this.$http.post("CQ/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.cqList=response.data.DATA
                }
            })
    },
    getWellList(){
        this.$http.post("Well/GetDataList", {})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.wellList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.name,
            code:this.code,
            CQSNOWID:this.cqid,
            EJDW:this.ejdw,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Land/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    addPost(){
        this.addr=[]
        this.postInfo={
            displayname:"",
            zsbh:"",
            provinces:"",
            city:"",
            district:"",
            JTZL:"",
            mJ_M:"",

            bdcdyh:"",
            qllx:"",
            zdwjh:"",
            syqx:"",
            fzrq:"",
            sfyzddt:"0",
            sfyzb:"0",

            zztdsyqxz:"",
            zztdyt:"",
            tdxyt:"",
            YDSJ:"",
            sfydfzfdj:"",
            sfywcbg:"",
            ejdw:"",
            bghbdczh:"",
            sfkbg:"",
            sfytjbgsqjxgzl:"",
            wcbgsj:"",
            bkbgyy:"",
            bgjz:"",
            bz:"",
            code:"",
            cqsnowid:"",
            zbstr:"",
            dklb:"",
            xdm:"",
            pzyt:"",
            pzwh:"",
            pzmj:"",
            pzrq:"",
            //lyzT1:"",
            lyzT2:"",
            ktzdl:"",
            tddj:"",
            gddj:"",
            mj:"",
            
            
            djmj:"",
            djzt:"",
            syqlx:"",
        }
        this.doTypeStr="添加地块"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Land/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.addr=[]
        if(item.PROVINCES && item.PROVINCES!="" && item.CITY && item.CITY!="" && item.DISTRICT && item.DISTRICT!=""){
            this.addr=[item.PROVINCES,item.CITY,item.DISTRICT]
        }
        var YDSJ=""
        if(item.YDSJ){
            YDSJ=new Date(item.YDSJ)
        }
        var pzrq=""
        if(item.PZRQ){
            pzrq=new Date(item.PZRQ)
        }

        var syqx=""
        if(item.SYQX){
            syqx=new Date(item.SYQX)
        }
        var fzrq=""
        if(item.FZRQ){
            fzrq=new Date(item.FZRQ)
        }

        var sfyzddt="0"
        if(item.SFYZDDT){
            sfyzddt=item.SFYZDDT
        }

        var sfyzb="0"
        if(item.SFYZB){
            sfyzb=item.SFYZB
        }

        this.postInfo={
            SnowID:item.SNOWID,
            displayname:item.DISPLAYNAME,
            zsbh:item.ZSBH,
            provinces:item.PROVINCES,
            city:item.CITY,
            district:item.DISTRICT,
            JTZL:item.JTZL,
            mJ_M:item.MJ_M,


            bdcdyh:item.BDCDYH,
            qllx:item.QLLX,
            zdwjh:item.ZDWJH,
            syqx:syqx,
            fzrq:fzrq,
            sfyzddt:sfyzddt,
            sfyzb:sfyzb,

            zztdsyqxz:item.ZZTDSYQXZ,
            zztdyt:item.ZZTDYT,
            tdxyt:item.TDXYT,
            YDSJ:YDSJ,
            sfydfzfdj:item.SFYDFZFDJ,
            sfywcbg:item.SFYWCBG,
            ejdw:item.EJDW,
            bghbdczh:item.BGHBDCZH,
            sfkbg:item.SFKBG,
            sfytjbgsqjxgzl:item.SFYTJBGSQJXGZL,
            wcbgsj:item.WCBGSJ,
            bkbgyy:item.BKBGYY,
            bgjz:item.BGJZ,
            bz:item.BZ,
            code:item.CODE,
            zbstr:item.ZBSTR,
            dklb:item.DKLB,
            xdm:item.XDM,
            pzyt:item.PZYT,
            pzwh:item.PZWH,
            pzmj:item.PZMJ,
            pzrq:pzrq,
            //lyzT1:item.LYZT1,
            lyzT2:item.LYZT2,
            ktzdl:item.KTZDL,
            tddj:item.TDDJ,
            gddj:item.GDDJ,
            mj:item.MJ,
            djmj:item.DJMJ,
            djzt:item.DJZT,
            syqlx:item.SYQLX,
            cqsnowid:item.CQSNOWID,
        }
        this.doTypeStr="修改地块"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Land/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.tianmapModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(typeof this.postInfo.YDSJ === 'object'){
                        this.postInfo.YDSJ = this.comjs.getDateMinStrByDate(this.postInfo.YDSJ)
                    }
                    if(typeof this.postInfo.pzrq === 'object'){
                        this.postInfo.pzrq = this.comjs.getDateMinStrByDate(this.postInfo.pzrq)
                    }

                    if(typeof this.postInfo.syqx === 'object'){
                        this.postInfo.syqx = this.comjs.getDateMinStrByDate(this.postInfo.syqx)
                    }
                    if(typeof this.postInfo.fzrq === 'object'){
                        this.postInfo.fzrq = this.comjs.getDateMinStrByDate(this.postInfo.fzrq)
                    }

                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除地块：'+item.TRUENAME+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            this.sendDel(item.SNOWID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }

    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Land/DeleteData", {SnowID:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    tianArea(item){
        this.postInfo={
            SnowID:item.SNOWID,
            displayname:item.DISPLAYNAME,
            zsbh:item.ZSBH,
            provinces:item.PROVINCES,
            city:item.CITY,
            district:item.DISTRICT,
            JTZL:item.JTZL,
            mJ_M:item.MJ_M,
            zztdsyqxz:item.ZZTDSYQXZ,
            zztdyt:item.ZZTDYT,
            tdxyt:item.TDXYT,
            YDSJ:item.YDSJ,
            sfydfzfdj:item.SFYDFZFDJ,
            sfywcbg:item.SFYWCBG,
            ejdw:item.EJDW,
           
            bghbdczh:item.BGHBDCZH,
            sfkbg:item.SFKBG,
            sfytjbgsqjxgzl:item.SFYTJBGSQJXGZL,
            wcbgsj:item.WCBGSJ,
            bkbgyy:item.BKBGYY,
            bgjz:item.BGJZ,
            bz:item.BZ,
            code:item.CODE,
            zbstr:item.ZBSTR,
            dklb:item.DKLB,
            xdm:item.XDM,
            pzyt:item.PZYT,
            pzwh:item.PZWH,
            pzmj:item.PZMJ,
            pzrq:item.PZRQ,
            //lyzT1:item.LYZT1,
            lyzT2:item.LYZT2,
            ktzdl:item.KTZDL,
            tddj:item.TDDJ,
            gddj:item.GDDJ,
            mj:item.MJ,
            djmj:item.DJMJ,
            djzt:item.DJZT,
            syqlx:item.SYQLX,
            cqsnowid:item.CQSNOWID,
        }
        this.tianmapModal=true
        this.polygonStr=""
        if(this.tianmap){
            this.$refs.tianmap.clearDraw()
        }
        setTimeout(()=>{
            this.setProjectTDZB(item.ZBSTR)
        },500)
    },
    setProjectTDZB(zb){
        if(zb && zb!=""){
            var clist=[]
            if(zb.indexOf("|")!= -1){
                clist=zb.split("|")
            }else{
                clist.push(zb)
            }
            var mcenter=[]
            for(var i=0;i<clist.length;i++){
                var pitems=[]
                var spitem=clist[i].split(";")
                for(var j=0;j<spitem.length;j++){
                    if(spitem[j]!="" && parseFloat(spitem[j].split(",")[0]) != 0 && parseFloat(spitem[j].split(",")[1])!= 0){
                        var ppitem=[parseFloat(spitem[j].split(",")[0]),parseFloat(spitem[j].split(",")[1])]
                        if(mcenter.length==0){
                            mcenter=ppitem
                        }
                        pitems.push(ppitem)
                    } 
                }
                if(pitems.length>0){
                    this.$refs.tianmap.appendFeature('Polygon',i,[pitems],false,null)
                }
            }
            if(mcenter.length==2){
                this.$refs.tianmap.moveTo(mcenter)
            }
        }
    },
    startTDraw(){
        this.$refs.tianmap.drawPolygon(null,null)
    },
    setMapPolygon(){
        var zb=this.polygonStr
        if(zb!=""){
            this.$confirm('本次操作会覆盖原先的坐标区域，确定继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs.tianmap.clearDraw()
                    var clist=[]
                    if(zb.indexOf("|")!= -1){
                        clist=zb.split("|")
                    }else{
                        clist.push(zb)
                    }
                    var mcenter=[]
                    for(var i=0;i<clist.length;i++){
                        var pitems=[]
                        var spitem=clist[i].split(";")
                        for(var j=0;j<spitem.length;j++){
                            if(spitem[j]!="" && parseFloat(spitem[j].split(",")[0]) != 0 && parseFloat(spitem[j].split(",")[1])!= 0){
                                var ppitem=[parseFloat(spitem[j].split(",")[0]),parseFloat(spitem[j].split(",")[1])]
                                if(mcenter.length==0){
                                    mcenter=ppitem
                                }
                                pitems.push(ppitem)
                            } 
                        }
                        if(pitems.length>0){
                            this.$refs.tianmap.appendFeature('Polygon',i,[pitems],false,null)
                        }
                    }
                    if(mcenter.length==2){
                        this.$refs.tianmap.moveTo(mcenter)
                    }
                })
        }else{
            this.$message.error("请输入正确的坐标字符串")
        }
    },
    delTDraw(){
        var  ps=this.$refs.tianmap.getCheckDrawFeatures()
        if(ps.length>0){
            this.$confirm('确定删除当前选中的区域？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$refs.tianmap.delCheckDrawFeatures()
                this.$message.info("已删除")    
            })
        }else{
            this.$message.error("请先选择一个区域")
        }
    },
    submitTianForm(){
       var feas=this.$refs.tianmap.getAllDrawFeatures()
       if(feas.length>0){
            this.postInfo.zbstr = this.getTDDrawPolygonsWKTStr()
            // var center =this.getTDDrawPolyginsCenter()
            // this.postInfo.LON = center[0]
            // this.postInfo.LAT = center[1]
            this.sendEdit()
       }else{
         this.$message.error("请先绘制区域")
       }
    },
    getTDDrawPolygonsWKTStr(){
        var list=this.$refs.tianmap.getAllDrawFeatures()
        var pgStr=""
        var isMutil=false
        if(list.length>0){
            for(var i=0;i<list.length;i++){
                var path=list[i].getGeometry().getCoordinates()[0]
                for(var j=0;j<path.length;j++){
                    if(path[j][0] !="" && path[j][1]!=""){
                        pgStr+=path[j][0]+","+path[j][1]
                        if(j< path.length-1){
                            pgStr+=";"
                        }
                    }
                }
                if(i< list.length-1){
                    pgStr+="|"
                }
            }
        }
        return pgStr 
    },
    getTDDrawPolyginsCenter(){
        var list=this.$refs.tianmap.getAllDrawFeatures()
        var litem=list[0].getGeometry().getCoordinates()
        litem[0].push(litem[0][0])
        var  p=this.$refs.tianmap.getTurfPolygon(litem)
        var center=this.$refs.tianmap.getPolygonCenter(p)
        return center.geometry.coordinates
    },
    bindWell(item){
        this.bindModal=true
        this.bindDatas={
            LandSnowID:item.SNOWID,
            WellSnowIDList:[],
        }
        this.getWellByLandId()
    },
    getWellByLandId(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Well/GetDataList", {LandSnowID:this.bindDatas.LandSnowID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        this.bindDatas.WellSnowIDList.push(list[i].SNOWID)
                    }
                } 
            })
            .catch((error) => {
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    sendBindWell(){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("Land/BandWells", this.bindDatas)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已保存")
                        this.bindModal=false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                    if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
                });
        }
    },
    exportPost(){
        this.chooseFileModal=true 
    },
    uploadFun(f){
        //?token="+this.$store.state.token
      this.showLoading("请稍后...")
      var fd = new FormData();
      fd.append("file", f.file);
      this.$upload.post("Land/UpLoadLands", fd)
        .then((response) => {
         this.hideLoading()
            if(response.data.SUCCESS) {
                this.hideLoading()
                this.chooseFileModal=false
                this.PageIndex=1
                this.getPostList()
                this.$message.success("导入完成")
            }else{
                this.hideLoading()
                this.$message.error("上传失败")
            }
        })
        .catch((error) => {
            this.hideLoading()
            if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },


    //成果汇交
    fruitSubmit(item){
        if(this.canDo){
            this.smjFile=null
            this.smjFileSrc=""
            this.smjFileId=""
            this.smjFileName=""
            this.chooseSmjFile=false

            this.cadFile=null
            this.cadFileSrc=""
            this.cadFileId=""
            this.cadFileName=""
            this.chooseCadFile=false

            this.friuitLandName=item.DISPLAYNAME
            this.doTypeStr=item.DISPLAYNAME+"成果汇交登记"

            this.canDo=false
            this.showLoading("请稍后")
            var params={
                landSnowID:item.SNOWID
            }
            this.$http.post("ExchangeResult/GetDataList", params)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        if(response.data.DATA.length>0){
                            this.editFruitSubmit(response.data.DATA[0])
                        }else{
                            this.addFruitSubmit(item)
                        }
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true   
                    if (error.isAxiosError) {
                        if(error.response.status==401){
                            this.logout()
                        }
                    }
                });
        }
        this.addFruitSubmit(item)
    },

    addFruitSubmit(item){
        var zsbh=""
        if(item.ZSBH && item.ZSBH!=""){
            zsbh = item.ZSBH
        }
        var ejdw=""
        if(item.EJDW && item.EJDW!=""){
            ejdw = item.EJDW
        }
        var zl=""
        if(item.JTZL && item.JTZL!=""){
            zl = item.JTZL
        }
        var bdcdyh=""
        if(item.BDCDYH && item.BDCDYH!=""){
            bdcdyh = item.BDCDYH
        }
        var qllx=""
        if(item.QLLX && item.QLLX!=""){
            qllx = item.QLLX
        }
        var qlxz=""
        if(item.SYQLX && item.SYQLX!=""){
            qlxz = item.SYQLX
        }
        var yt=""
        if(item.TDXYT && item.TDXYT!=""){
            yt = item.TDXYT
        }
        var mj=""
        if(item.MJ_M){
            mj = item.MJ_M
        }
        var syqx=""
        if(item.SYQX && item.SYQX!=""){
            syqx = item.SYQX
        }
        var fzdw=""
        if(item.DISTRICT && item.DISTRICT!=""){
            fzdw = item.DISTRICT
        }
        this.fruitInfo={
            landSnowID:item.SNOWID,
            landStatus:"1",
            submitUserSnowID:this.$store.state.snowid,
            ejdw:"",
            zsbh:zsbh,
            qlr:"",
            gyqk:"",
            zl:zl,
            bdcdyh:bdcdyh,
            qllx:qllx,
            qlxz:qlxz,
            yt:yt,
            mj:mj,
            syqx:syqx,
            qlqtzk:"",
            fzdw:fzdw,
            smj:""
        }
    
        this.doType="1"
        this.fruitModal=true
    },
    editFruitSubmit(item){
        var  smj=""
        for(var i=0;i<item.Files.length;i++){
            if(item.Files[i].ImageType ==2){
                smj="1"
                this.smjFileName="证书扫描件.pdf"
                this.smjFileSrc=item.Files[i].Path
                this.smjFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==3){
                this.cadFileName="CAD源文件.wdg"
                this.cadFileSrc=item.Files[i].Path
                this.cadFileId=item.Files[i].SnowID
            }
        }
        this.fruitInfo={
            SnowID:item.SnowID,
            landSnowID:item.LandSnowID,
            landStatus:item.LandStatus,
            submitUserSnowID:this.$store.state.snowid,
            ejdw:item.EJDW,
            zsbh:item.ZSBH,
            qlr:item.QLR,
            gyqk:item.GYQK,
            zl:item.ZL,
            bdcdyh:item.BDCDYH,
            qllx:item.QLLX,
            qlxz:item.QLXZ,
            yt:item.YT,
            mj:item.MJ,
            syqx:item.SYQX,
            qlqtzk:item.QLQTZK,
            fzdw:item.FZDW,
            smj:smj
        }
        this.doType="2"
        this.fruitModal=true
    },

    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    if(this.doType=="1"){
                        this.sendFAdd()
                    }else if(this.doType=="2"){
                        this.sendFEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendFAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/InsertData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fruitModal=false
                    if(this.chooseSmjFile){
                        this.addImgC(response.data.DATA)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(response.data.DATA)
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    sendFEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/UpdateData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已提交")
                    this.fruitModal=false
                    if(this.chooseSmjFile){
                        this.addImgC(this.fruitInfo.SnowID)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(this.fruitInfo.SnowID)
                    }
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delFruit(){
        if(this.canDo){
            this.$confirm('确定删除地块'+this.friuitLandName+'的成果汇交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelFruit()
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelFruit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/DeleteData", {snowid:this.fruitInfo.SnowID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.fruitModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        if( this.smjFileId!=""){
            this.sendDelFile(this.smjFileId)
        }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        if( this.cadFileId!=""){
            this.sendDelFile(this.cadFileId)
        }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
    },

    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

.rowExpand{overflow:hidden}
.rowExpand div{float:left;font-size:12px;}
</style>
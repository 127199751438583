<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">证书类型管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="名称"></el-table-column>
                    <el-table-column prop="Factor" label="系数"></el-table-column>
                    <el-table-column prop="NotRight" label="是否无权源">
                        <template slot-scope="scope">
                            <span v-if="scope.row.NotRight== 1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="系数" prop="factor">
                    <el-input v-model="postInfo.factor" autocomplete="off" placeholder="系数必须大于0且只保留两位小数"></el-input>
                </el-form-item>
                <el-form-item label="是否无权源">
                    <el-radio v-model="postInfo.notRight" label="1">是</el-radio>
                    <el-radio v-model="postInfo.notRight" label="0">否</el-radio>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "LandTypeManage",
  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };

    var factor=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入系数"));
      }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('系数只能是数字'));
        }else if(value<=0){
            callback(new Error('系数必须大于0'));
        } else {
            callback();
        }
    };

    
    return {
        canDo:true,
        showPagination:false,
        name:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            factor: [{ validator: factor, trigger: "blur" }],
        },
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
    }
    this.loadItem=null
    this.getPostList()
  },
  methods: {

    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("ZSType/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            name:"",
            factor:"",
            notRight:"0",
        }
        this.doTypeStr="添加证书类型"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ZSType/InsertOrUpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            factor:item.Factor,
            notRight:item.NotRight.toString(),
        }
        this.doTypeStr="修改证书类型"
        this.doType="2"
        this.postModal=true
    },

    
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.postInfo.factor=this.comjs.toFixedPrice(this.postInfo.factor)
                    this.sendAdd()
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ZSType/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
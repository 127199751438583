<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">成果地块类型管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="名称"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                        <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                        <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="80px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "LandTypeManage",
  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    
    return {
        canDo:true,
        showPagination:false,
        name:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
        },
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
    }
    this.loadItem=null
    this.getPostList()
  },
  methods: {

    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("LandType/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            name:"",
        }
        this.doTypeStr="添加地块类型"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandType/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
        }
        this.doTypeStr="修改地块类型"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandType/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if(response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                }else{
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除类型：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("LandType/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>
<template>
    <div class="views-main">
      <div class="views-main-title">
        <p class="txt">行政区系数设置</p>
      </div>
      <div class="tableBox" style="top:50px;">
        <div class="views-main-search">
          <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width:200px;"> </el-input>
          <el-button type="primary" @click="sendQuery" class="ml10">查询</el-button>
          <el-button type="success" @click="addDep" class="ml10">添加</el-button>
        </div>
        <div class="views-main-body">
            <el-tree :props="props" :load="getList" lazy  ref="tree" node-key="guid"  :filter-node-method="filterNode">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="treeName">{{ node.label }}<small style="color:#409EFF;font-size:12px;">(系数:{{data.xs}})</small></span>
                <span>
                  　
                  <el-button type="text" size="mini" @click.stop="() => append(node, data)" style="color:#19be6b" v-if="!data.leaf">
                    添加
                  </el-button>
                  <el-button type="text" size="mini" @click.stop="() => edit(node, data)" style="color:#ff9900">
                    编辑
                  </el-button>
                  <el-button type="text" size="mini" @click.stop="() => remove(node, data)" style="color:#ed4014">
                    删除
                  </el-button>
                </span>
              </span>
            </el-tree>
        </div>
      </div>
      <el-dialog :title="DepModalTitle" :visible.sync="DepModal" width="500px">
        <el-form :model="DepInfo" status-icon :rules="DepRules"  ref="DepForm" label-width="110px" class="demo-DepForm" >
          <el-form-item label="选择行政区" prop="regionName">
            <el-select v-model="DepInfo.regionName" placeholder="请选择行政区"  filterable  style="width:100%;" :disabled="DepModalType=='2'" >
                <el-option v-for="item in xzqList" :key="item.name" :label="item.name" :value="item.name"  :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="系数" prop="factor">
                    <el-input v-model="DepInfo.factor" autocomplete="off" placeholder="系数必须大于0且只保留两位小数"></el-input>
                </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitDepForm()">提交</el-button>
            <el-button @click="closeDepForm()">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </template>
  <script>
  var h=document.body.clientHeight
  export default {
    name: "WZHPFL",
    data() {
      var regionName = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请选择行政区"));
        } else {
          callback();
        }
      };

      var factor=(rule, value, callback) => {
        if (value === "" || value == null) {
            callback(new Error("请输入系数"));
        }else if(typeof value != 'number' && isNaN(value)){
                callback(new Error('系数只能是数字'));
            }else if(value<=0){
                callback(new Error('系数必须大于0'));
            } else {
                callback();
            }
        };

      return {
        allXzq:[],
        xzqList:[],
        props: {
            label: 'name',
            children: 'zones',
            isLeaf: 'leaf'
        },
        filterText:"",
  
        DepModalType:"",
        DepModalTitle:"",
  
        DepParentID:"",
        DepParentNAME:"",   
  
        DepModal:false,
        DepInfo:{},
        DepRules: {
            regionName: [{ validator: regionName, trigger: "blur" }],
            factor: [{ validator: factor, trigger: "blur" }],
        },
        DepEditItem:null,
        canDo:true,
        auditText:"",

        haveList:[],
      };
    },
    mounted() {
      this.allXzq=this.comjs.cityss
      this.dialogMaxHeigh = h -300
      this.loadItem=null
    },
    methods: {
        flagXzqHave(name){
            var f=false
            for(var i=0;i<this.haveList.length;i++){
                if(this.haveList[i].RegionName == name){
                    f=true
                    break;
                }
            }
            return f
        },
        flagXzqIsLast(name){
            var f=false
            var c=0
            for(var i=0;i<this.allXzq.length;i++){
                if(c==0){
                    var sheng=this.allXzq[i]
                    if(sheng.name==name){ //省
                        c=1
                        break
                    }else{
                        if(c==0){
                            for(var j=0;j<sheng.children.length;j++){
                                var shi=sheng.children[j]
                                if(shi.name==name){  //市
                                    c=1
                                    break
                                }else{
                                    if(c==0){
                                        for(var k=0;k<shi.children.length;k++){
                                            var qu=shi.children[k]
                                            if(qu.name==name){
                                                c=1
                                                f=true
                                                break
                                            }
                                        }
                                    }else{
                                        break;
                                    }
                                }
                            }
                        }else{
                            break;
                        }
                    }
                }else{
                    break;
                }
            }
            return f
        },
        getXzqListByName(list ,name){
            var arr=[]
            var selarr=[]
            for(var i=0;i<list.length;i++){
                if(arr.length ==0){
                    var sheng=list[i]
                    if(sheng.name==name){
                        arr=sheng.children
                        break;
                    }else{
                        if(arr.length ==0){
                            for(var j=0;j<sheng.children.length;j++){
                                var shi=sheng.children[j]
                                if(shi.name==name){
                                    arr=shi.children
                                    break;
                                }
                            }
                        }else{
                            break;
                        }
                    }
                }else{
                    break;
                }
            }

            //this.DepParentID
            for(var k=0;k<arr.length;k++){
                var d=this.flagXzqHave(arr[k].name)
                selarr.push({name:arr[k].name,disabled:d})
            }
            return selarr
        },

        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        getList(node, resolve){
            var guid="0"
            var hasChild=true
            if(node.data != undefined){
                guid = node.data.guid
            }
            if(hasChild){
                setTimeout(()=>{
                    var params={
                        partentSnowID:guid,
                    }
                
                this.$http.post("RegionFactor/GetDataList",params)
                .then((response) => {
                    if(response.data.SUCCESS){   
                        var l=response.data.DATA
                        var d=[]
                        for(var i=0;i<l.length;i++){
                            d.push({
                                guid:l[i].SnowID,
                                name: l[i].RegionName,
                                pid:l[i].PARENTSNOWID,
                                xs:l[i].Factor,
                                leaf: this.flagXzqIsLast(l[i].RegionName),
                            }) 
                        }
                        resolve(d);
                    }
                })
                },100)
                
            }
    
        },
        sendQuery(){
            var val=this.filterText
            this.$refs.tree.filter(val)
        },
        closeDepForm(){
            this.DepModal=false
        },
        submitDepForm(){
            if (this.canDo) {
                this.$refs.DepForm.validate((valid) => {
                if (valid) {
                    if(this.DepModalType=="1"){
                    this.appendDep()
                    }else if(this.DepModalType=="2"){
                    this.editDep()  
                    }
                } else {
                return false;
                }
            });
            }
        },
        appendDep(){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("RegionFactor/InsertOrUpdateData", this.DepInfo)
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                this.DepModal=false
                if(response.data.SUCCESS){   
                    var data={
                        guid:response.data.DATA,
                        name: this.DepInfo.regionName,
                        pid:this.DepParentID,
                        xs:this.DepInfo.factor,
                        leaf: false,
                    }
                    var  pid=null
                    if(this.DepParentID!="0"){
                        pid=this.DepParentID
                    }
                    this.$refs.tree.append(data,pid) 
                    this.$message.success("添加成功")
                }else{
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                    
                    if (error.isAxiosError) {
                        if(error.response.status==401){
                            this.logout()
                        }
                    }
                    this.hideLoading()
                    this.canDo=true
                    this.$message.error("请求出错")
                });
        },
        editDep(){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("RegionFactor/InsertOrUpdateData",this.DepInfo )
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                this.DepModal=false
                if(response.data.SUCCESS){   
                this.$set(this.DepEditItem,'name',this.DepInfo.regionName)
                this.$set(this.DepEditItem,'xs',this.DepInfo.factor)
                this.$message.success("编辑成功")
                }else{
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                    if (error.isAxiosError) {
                        if(error.response.status==401){
                            this.logout()
                        }
                    }
                    this.hideLoading()
                    this.canDo=true
                    this.$message.error("请求出错")
                });
        },
        addDep(){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("RegionFactor/GetDataList",{partentSnowID:"0"})
            .then((response) => {
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    this.haveList=response.data.DATA
                    this.DepInfo={
                        snowID:"",
                        regionName:"",
                        factor:"",
                        parentSnowID:"0",
                    },
                    this.DepModalType="1"
                    this.DepParentID="0"
                    var xzqs=[]
                    for(var i=0;i<this.allXzq.length;i++){
                        var d=this.flagXzqHave(this.allXzq[i].name)
                        xzqs.push({name:this.allXzq[i].name,disabled:d})
                    }
                    this.xzqList=xzqs
                    this.DepModalTitle="添加行政区系数"
                    this.DepModal=true
                }
            })
        },
        append(node, data){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("RegionFactor/GetDataList",{partentSnowID:data.guid})
            .then((response) => {
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    this.haveList=response.data.DATA
                    this.DepInfo={
                        snowID:"",
                        regionName:"",
                        factor:"",
                        parentSnowID:data.guid,
                    },
                    this.DepModalType="1"
                    this.DepParentID=data.guid
                    this.DepEditItem=data

                    this.xzqList=this.getXzqListByName(this.allXzq,data.name)
                    this.DepModalTitle="添加行政区系数"
                    this.DepModal=true
                }
            })



 
        },
        edit(node, data){
            this.DepInfo={
                snowID:data.guid,
                regionName:data.name,
                factor:data.xs,
                parentSnowID:data.pid,
            },
            this.DepEditItem=data
            this.DepModalType="2"
            this.DepParentID=data.pid
            this.DepModalTitle="编辑行政区系数"
            this.DepModal=true
    
        },
        remove(node, data){
            if(this.canDo){
            this.$confirm('确定删除'+data.name+'及其全部的子级数据？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.delDep(data)
                }).catch(() => {
                    this.$message.info("已取消")    
                });
            }
        },
        delDep(data){
            this.canDo=false
            this.showLoading("请稍后")
            this.$http.post("RegionFactor/DeleteData", {snowid:data.guid})
            .then((response) => {
                //console.log(response)
                this.hideLoading()
                this.canDo=true
                if(response.data.SUCCESS){   
                    this.$refs.tree.remove(data)
                    this.$message.success("删除成功")
                }else{
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                }
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
            });
        },
    },
    computed: {
    },
    watch: {
    },
  };
  </script>
  <style >
    .el-tree-node{margin-top: 20px;}
    .treeName{font-size: 16px;margin-right: 5px;}
  
    .funTreeRow{width: 500px;}
    .funtreeName{font-size: 15px;margin-right: 30px;}
    .funItemList{float:right;}
    .managerName{font-size: 12px;margin-right: 20px;color: #409EFF;}
    .custom-tree-node{display: block;}
    .el-checkbox{margin-right: 15px!important;}
    .el-checkbox__label{padding-left: 5px!important;}
  </style>
  
<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">难度级别设置</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入名称" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="名称"></el-table-column>
                    <el-table-column prop="Minfactor" label="开始系数"></el-table-column>
                    <el-table-column prop="Maxfactor" label="结束系数"></el-table-column>
                    <el-table-column prop="Price" label="价格"></el-table-column>
                    <el-table-column prop="IsDefault" label="是否默认">
                        <template slot-scope="scope">
                            <span v-if="scope.row.IsDefault== 1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="PageIndex" :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="120px" class="demo-uForm">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="开始系数" prop="minFactor">
                    <el-input v-model="postInfo.minFactor" autocomplete="off" placeholder="系数必须大于0且只保留两位小数"></el-input>
                </el-form-item>
                <el-form-item label="结束系数" prop="maxFactor">
                    <el-input v-model="postInfo.maxFactor" autocomplete="off" placeholder="结束系数必须大于开始系数"></el-input>
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input v-model="postInfo.price" autocomplete="off" placeholder="系数必须大于0且只保留两位小数"></el-input>
                </el-form-item>
                <el-form-item label="是否默认">
                    <el-radio v-model="postInfo.isDefault" label="1">是</el-radio>
                    <el-radio v-model="postInfo.isDefault" label="0">否</el-radio>
                    <p>如果系数落空，则取默认值</p>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "Degree",
  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };

    var minFactor=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入系数"));
      }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('系数只能是数字'));
        }else if(value<0){
            callback(new Error('开始系数必须大于等于0'));
        } else {
            callback();
        }
    };

    var maxFactor=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入系数"));
      }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('系数只能是数字'));
        }else if(value<=0){
            callback(new Error('结束系数必须大于0'));
        }else if(this.postInfo.minFactor !='' && parseFloat(value)<parseFloat(this.postInfo.minFactor)){
            callback(new Error('结束系数必须大于等于开始系数'));
        } else {
            callback();
        }
    };

    var price=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入价格"));
      }else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('系数只能是数字'));
        }else if(value<=0){
            callback(new Error('价格必须大于0'));
        } else {
            callback();
        }
    };
    
    return {
        canDo:true,
        showPagination:false,
        name:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            minFactor: [{ validator: minFactor, trigger: "blur" }],
            maxFactor: [{ validator: maxFactor, trigger: "blur" }],
            price: [{ validator: price, trigger: "blur" }],
        },
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
    }
    this.loadItem=null
    this.getPostList()
  },
  methods: {

    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("FactorLevel/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            name:"",
            minFactor:"",
            maxFactor:"",
            price:"",
            isDefault:"0",
        }
        this.doTypeStr="添加难度级别"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("FactorLevel/InsertOrUpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已保存")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    editPost(item){
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            minFactor:item.Minfactor,
            maxFactor:item.Maxfactor,
            price:item.Price,
            isDefault:item.IsDefault.toString(),
        }
        this.doTypeStr="修改难度级别"
        this.doType="2"
        this.postModal=true
    },

    
    submitPostForm() {
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    this.postInfo.minFactor=this.comjs.toFixedPrice(this.postInfo.minFactor)
                    this.postInfo.maxFactor=this.comjs.toFixedPrice(this.postInfo.maxFactor)
                    this.postInfo.price=this.comjs.toFixedPrice(this.postInfo.price)
                    this.sendAdd()
                } else {
                    return false;
                }
            });
        }
    },
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("FactorLevel/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>